import React from "react";
import {faTimeline} from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faTimeline}
				title="Timeline"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="g19.png"
								alt="facebook"
								className="work-image"
							/>
							<div className="work-title">G19 Graphics</div>
							<div className="work-subtitle">
								Fullstack Engineer
							</div>
							<div className="work-duration">2024 - Present</div>
						</div>

						<div className="work">
							<img
								src="university.png"
								alt="uofa"
								className="work-image"
							/>
							<div className="work-title">University of Alberta</div>
							<div className="work-subtitle">
								B.Sc., Spec. in Comp. Sci.
							</div>
							<div className="work-duration">2023 June</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
