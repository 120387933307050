import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Article from "../components/articles/article";

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";

import "./styles/articles.css";
import {useFetchArticles} from "../hooks/useFetchArticles";

const Articles = () => {

	const { articles, loading, error } = useFetchArticles("https://blog.api.shiguofeng.com/article/articleList/?pageNum=1&pageSize=5");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "articles");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Articles | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="articles" />
				<div className="content-wrapper">
					<div className="articles-logo-container">
						<div className="articles-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="articles-main-container">
						<div className="title articles-title">
							{INFO.articles.title}
						</div>

						<div className="subtitle articles-subtitle">
							{INFO.articles.description}
						</div>

						{/*<div className="articles-container">*/}
						{/*	<div className="articles-wrapper">*/}
						{/*		{myArticles.map((article, index) => (*/}
						{/*			<div*/}
						{/*				className="articles-article"*/}
						{/*				key={(index + 1).toString()}*/}
						{/*			>*/}
						{/*				<Article*/}
						{/*					key={(index + 1).toString()}*/}
						{/*					date={article().date}*/}
						{/*					title={article().title}*/}
						{/*					description={article().description}*/}
						{/*					link={"/article/" + (index + 1)}*/}
						{/*				/>*/}
						{/*			</div>*/}
						{/*		))}*/}
						{/*	</div>*/}
						{/*</div>*/}
						<div className="articles-container">
							<div className="articles-wrapper">
								{articles.map((article) => (
									<div className="articles-article" key={article.id}>
										<Article
											date={new Date(article.createTime).toLocaleDateString()}
											title={article.title}
											description={article.summary}
											link={`https://blog.shiguofeng.com/DetailArticle?aid=${article.id}`}
										/>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Articles;
