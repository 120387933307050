import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/about.css";
import Works from "../components/homepage/works";
import {faDatabase, faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
	faAws,
	faCss3Alt,
	faDocker,
	faGit,
	faHtml5,
	faJava,
	faJs,
	faPython,
	faReact,
	faSass
} from '@fortawesome/free-brands-svg-icons';

const iconMapping = {
	"html-5": faHtml5,
	"css3": faCss3Alt,
	"sass": faSass,
	"Js/Ts": faJs,
	"React": faReact,
	"git": faGit,
	"sql-database": faDatabase,
	"java": faJava,
	"python": faPython,
	"docker": faDocker,
	"aws": faAws,
};

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "about");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`About | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					<div className="about-logo-container">
						<div className="about-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="about-container">
						<div className="about-main">
							<div className="about-right-side">
								<div className="title about-title">
									{INFO.about.title}
								</div>

								<div className="subtitle about-subtitle">
									{INFO.about.description}
								</div>

								<div className="software-skills-main-div">
									<div className="title skill-title">
										Tools I used
									</div>
										<ul className="dev-icons">
										{INFO.softwareSkills.map((skill, i) => {
											const icon = iconMapping[skill];
											return (
												<li
													key={i}
													className="software-skill-inline"
												>
													<FontAwesomeIcon icon={icon} className="skill-icon" />
													<p>{skill}</p>
												</li>
											);
										})}
									</ul>
								</div>
							</div>

							<div className="about-left-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src="about.jpg"
											alt="about"
											className="about-image"
										/>
									</div>
								</div>

								{/*<div className="about-socials">*/}
								{/*	<Works />*/}
								{/*</div>*/}

								<a
									href="resume.pdf"
									className="download-resume-button"
									download
								>
									<FontAwesomeIcon icon={faDownload} className="button-icon" />
									Get My Resume
								</a>
							</div>
						</div>
					</div>


					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;
