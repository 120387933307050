import { useState, useEffect } from "react";

export const useFetchArticles = (url) => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch(url);
                const data = await response.json();
                if (data.code === 200) {
                    setArticles(data.data.rows);
                } else {
                    setError(`Failed to fetch articles: ${data.msg}`);
                }
            } catch (err) {
                setError(`Error fetching articles: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [url]);

    return { articles, loading, error };
};
