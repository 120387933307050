import React from 'react';
import Modal from 'react-modal';
import "./styles/projectModal.css";

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const customStyles = {
    content: {
        // 移除垂直居中定位
        // top: '50%',
        // transform: 'translate(-50%, -50%)',
        // 保留水平居中
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translateX(-50%)',
        width: '65%',
        maxWidth: '800px',
        // 新增最大高度和滚动
        maxHeight: '90vh',
        overflowY: 'auto',
        padding: '20px',
        borderRadius: '10px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 1000,
    },
};


Modal.setAppElement('#root');

const ProjectModal = ({ isOpen, onRequestClose, project }) => {

    const paragraphs = project?.description?.split('\n') ?? [];

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Project Details"
            style={customStyles}
        >
            {project && (
                <div className="project-modal-container">
                    <button className="close-button" onClick={onRequestClose}>
                            &times;
                        </button>
                        <h2 className="project-modal-title">{project.title}</h2>
                        <p className="project-modal-description">
                        {paragraphs.map((para, index) => (
                            <p key={index}>{para}</p>
                        ))}
                        </p>

                        <div className="project-modal-tech-tags">
                            {project.techTags.map((tag, index) => (
                                <span key={index} className="tech-tag">{tag}</span>
                            ))}
                        </div>


                        <AwesomeSlider className="project-modal-slider">
                            {project.images.map((image, index) => (
                                <div key={index} data-src={image} />
                            ))}
                        </AwesomeSlider>

                    <div className="button-container">
                        <a
                            rel="noreferrer"
                            target="_blank"
                            className="cta-btn"
                            href={project.liveLink}
                        >
                            See Live
                        </a>
                        { !!project.otherLink &&
                        <a
                            rel="noreferrer"
                            target="_blank"
                            className="cta-btn"
                            href={project.otherLink}
                        >
                            {project.more}
                        </a>
                        }
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ProjectModal;
