import React, {useState} from "react";

import INFO from "../../data/user";
import "./styles/allProjects.css";
import FeatureProject from "./featureProject";
import ProjectModal from "./projectModal";

const FeaturedProjects = () => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedProject(null);
    };

    return (
        <>
            <div className="all-projects-container">
                {INFO.featureProjects.map((project, index) => (
                    <div
                        className="feature-projects-project"
                        key={index}
                    >
                        <FeatureProject
                            thumbnail={project.thumbnail}
                            description={project.description}
                            onLearnMoreClick={() => handleProjectClick(project)}
                        />
                    </div>
                ))}
            </div>

            <ProjectModal
                isOpen={modalOpen}
                onRequestClose={handleCloseModal}
                project={selectedProject}
            />
        </>
    );
};

export default FeaturedProjects;