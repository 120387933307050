import React from "react";
import "./styles/featureProject.css";

const FeatureProject = (props) => {
	const { thumbnail, description, onLearnMoreClick} = props;

	return (
		<React.Fragment>
			<div className="feature-project">
				<div className="feature-project-container">
					<div className="feature-project-thumbnail">
						<img src={thumbnail} alt="thumbnail" />
					</div>
					<div className="feature-project-overlay">
						<div className="feature-project-description">{description}</div>
						<button className="learn-more-button" onClick={onLearnMoreClick}>
							Explore Project
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default FeatureProject;
